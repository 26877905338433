import gql from 'graphql-tag'

export const CURRENT_USER = gql`
query {
  currentUser {
    id
    firstname
    surname
    cellphone
    email
    admin
  }
}`

const openDocuments = `
openDocuments {
  id
  title
  isOpen
  openAt
  closeAt
  actualDocument
}`

export const ALL_DOCUMENTS = gql`
query {
  allDocuments {
    id
    title
    isOpen
    actualDocument
  }
}`

const document = `
document (id: $id) {
  id
  title
  url
  openAt
  closeAt
  isOpen
  sections { id number title }
  actualDocument
}`

export const DOCUMENT = gql`
query ($id: ID!) {
  ${document}
}`

export const DOCUMENT_AND_AMENDMENTS = gql`
query ($id: ID!) {
  ${document}
  amendments: documentAmendments (documentId: $id) {
    id
    user { firstname surname }
    section { id number title }
    type
    title
    text
    motivation
    proposer
    proposerContact
    structure
    seconder
    seconderContact
    withdrawn
  }
}`

export const ADD_AMENDMENT = gql`
mutation ($amendment: AmendmentInput!) {
  addAmendment (amendment: $amendment) {
    id
    user { id }
    section { id }
    type
    title
    text
    motivation
    proposer
    proposerContact
    structure
    seconder
    seconderContact
    withdrawn
  }
}`

export const UPDATE_AMENDMENT = gql`
mutation ($id: ID!, $amendment: AmendmentInput!) {
  updateAmendment (id: $id, amendment: $amendment) {
    id
    user { id }
    section { id }
    type
    title
    text
    motivation
    proposer
    proposerContact
    structure
    seconder
    seconderContact
    withdrawn
  }
}`

export const WITHDRAW_AMENDMENT = gql`
mutation ($id: ID!) {
  withdrawAmendment (id: $id) {
    id
    user { id }
    section { id }
    type
    title
    text
    motivation
    proposer
    proposerContact
    structure
    seconder
    seconderContact
    withdrawn
  }
}`

const myAmendments = `
myAmendments {
  id
  type
  title
  section {
    id
    number
    title
    document {
      id
      title
      isOpen
      actualDocument
    }
  }
  withdrawn
}`

export const MY_AMENDMENTS = gql`
query {
  ${myAmendments}
}`

export const AMENDMENT = gql`
query ($id: ID!) {
  amendment(id: $id) {
    id
    section {
      id
      number
      title
      document {
        id
        title
        url
        isOpen
        sections { id number title }
        actualDocument
      }
    }
    type
    title
    text
    motivation
    proposer
    proposerContact
    structure
    seconder
    seconderContact
    withdrawn
  }
}`

const draftFields = `
id
document { id title actualDocument }
section
title
proposer
seconder
motivation
text
withdrawn
`

export const DOCUMENT_AND_DRAFTS = gql`
query ($id: ID!) {
  ${document}
  drafts: documentDrafts (documentId: $id) {
    ${draftFields}
  }
}`

export const DRAFT = gql`
query ($id: ID!) {
  draft(id: $id) {
    ${draftFields}
  }
}
`

export const ADD_DRAFT = gql`
mutation ($draft: DraftInput!) {
  addDraft (draft: $draft) {
    ${draftFields}
  }
}`

export const UPDATE_DRAFT = gql`
mutation ($id: ID!, $draft: DraftInput!) {
  updateDraft (id: $id, draft: $draft) {
    ${draftFields}
  }
}`

export const WITHDRAW_DRAFT = gql`
mutation ($id: ID!) {
  withdrawDraft (id: $id) {
    ${draftFields}
  }
}`

const commentFields = `
id
draftamendment { id }
text
altFormula
`

export const ADD_COMMENT = gql`
mutation ($comment: DraftCommentInput!) {
  addComment (comment: $comment) {
    ${commentFields}
  }
}
`

export const DRAFT_AND_COMMENTS = gql`
query ($id: ID!) {
  draft(id: $id) {
    ${draftFields}
  }
  myCommentsOnDraft (draftamendmentId: $id) {
    ${commentFields}
  }
}
`

export const DOCUMENT_AND_DRAFTS_AND_COMMENTS = gql`
query ($id: ID!) {
  ${document}
  drafts: documentDrafts (documentId: $id) {
    ${draftFields}
  }
  comments: allCommentsOnDocument (documentId: $id) {
    ${commentFields}
    user { firstname surname cellphone email }
  }
}`

const finalAmendmentFields = `
id
document { id title actualDocument }
number
section
title
proposer
seconder
amendment
recommended
motivation
withdrawn
`

export const ADD_FINAL_AMENDMENT = gql`
mutation ($amendment: FinalInput!) {
  addFinalAmendment (amendment: $amendment) {
    ${finalAmendmentFields}
  }
}`

export const UPDATE_FINAL_AMENDMENT = gql`
mutation ($id: ID!, $amendment: FinalInput!) {
  updateFinalAmendment (id: $id, amendment: $amendment) {
    ${finalAmendmentFields}
  }
}`

const finalCommentFields = `
id
finalamendment { id }
comment
speaker
name
email
cellphone
points
position
`

export const ADD_FINAL_COMMENT = gql`
mutation ($comment: FinalCommentInput!) {
  addFinalComment (comment: $comment) {
    ${finalCommentFields}
  }
}
`

export const FINAL_AMENDMENT = gql`
query ($id: ID!) {
  finalAmendment(id: $id) {
    ${finalAmendmentFields}
  }
}
`

export const FINAL_AMENDMENT_AND_COMMENTS = gql`
query ($id: ID!) {
  finalAmendment(id: $id) {
    ${finalAmendmentFields}
  }
  myCommentsOnFinalAmendment (finalamendmentId: $id) {
    ${finalCommentFields}
  }
}
`

export const DOCUMENT_AND_FINAL_TITLES = gql`
query ($id: ID!) {
  ${document}
  amendments: documentFinalAmendments (documentId: $id) {
    id
    document { id }
    number
    section
    title
    proposer
    seconder
  }
}`

export const DOCUMENT_AND_FINAL_AMENDMENTS_AND_COMMENTS = gql`
query ($id: ID!) {
  ${document}
  amendments: documentFinalAmendments (documentId: $id) {
    ${finalAmendmentFields}
  }
  comments: allFinalCommentsOnDocument (documentId: $id) {
    ${finalCommentFields}
    user { firstname surname }
    createdAt
  }
}`

export const OPEN_DOCUMENTS_AND_MY_AMENDMENTS_AND_OPEN_DRAFTS_AND_OPEN_FINAL = gql`
query {
  ${openDocuments}
  ${myAmendments}
  openDrafts {
    id
    document { id title actualDocument }
    section
    title
    withdrawn
  }
  openFinalAmendments {
    ${finalAmendmentFields}
  }
}`
