import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

export const LoggingInSpinner = () => (
  <>
    <Spinner animation='border' variant='primary' role='status' />{' '}
    <span>Logging in...</span>
  </>
)

export const LoadingSpinner = () => (
  <Spinner animation='border' variant='primary' role='status'>
    <span className='sr-only'>Loading...</span>
  </Spinner>
)

export const SmallSpinner = ({ variant = 'primary' }) => <Spinner animation='border' variant={variant} role='status' size='sm' />
