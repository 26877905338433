import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { useAuthentication } from 'react-u5auth'
import { AuthProvider } from './infra/auth'
import { ApolloProvider } from './infra/apollo'
import { UserProvider } from './infra/user'

import ErrorBoundary from './containers/ErrorBoundary'
import Routes from './containers/Routes'

import './App.css'
import Layout from './presentation/Layout'
import { LoggingInSpinner } from './presentation/spinners'

const App = () => (
  <AuthProvider>
    <ApolloProvider>
      <BrowserRouter>
        <InnerApp />
      </BrowserRouter>
    </ApolloProvider>
  </AuthProvider>
)

function InnerApp () {
  const { authenticated } = useAuthentication()

  if (authenticated) {
    return (
      <UserProvider>
        <Layout>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Layout>
      </UserProvider>
    )
  } else {
    return (
      <Layout>
        <ErrorBoundary>
          <LoggingInSpinner />
        </ErrorBoundary>
      </Layout>
    )
  }
}

export default App
