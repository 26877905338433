import React from 'react'

import { ErrorMessage, ForbiddenMessage } from '../presentation/alerts'

export class ForbiddenError extends Error {}

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError (error) {
    return { error }
  }

  render () {
    const { error } = this.state
    if (error && error instanceof ForbiddenError) {
      return <ForbiddenMessage forbiddenItem={error.message} />
    } else if (error) {
      return <ErrorMessage message={error.message} />
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary
