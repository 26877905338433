import React from 'react'
import Alert from 'react-bootstrap/Alert'

export const ErrorMessage = ({ message }) => (
  <Alert variant='danger'>
    Unfortunately an error has occurred. The technical description is as follows.

    <blockquote><pre>{message}</pre></blockquote>

    Please check your internet connection and try refreshing this page.
    If errors continue to occur please contact <a href='mailto:support@da.org.za'>support@da.org.za</a> for assistance.
  </Alert>
)

export const ForbiddenMessage = ({ forbiddenItem }) => (
  <Alert variant='danger'>
    You are not allowed access to {forbiddenItem}. For assistance please contact <a href='mailto:support@da.org.za'>support@da.org.za</a>.
  </Alert>
)

export const AppForbiddenMessage = ({ forbiddenItem }) => (
  <Alert variant='danger'>
    Access to this app is restricted to DA members. For assistance please contact <a href='mailto:support@da.org.za'>support@da.org.za</a>.
  </Alert>
)
