import React, { createContext, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { CURRENT_USER } from './queries'

const UserContext = createContext()

export function UserProvider ({ children }) {
  const { loading, error, data } = useQuery(CURRENT_USER)

  if (error) throw error

  const value = {
    loading,
    user: data && data.currentUser
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export function useUser () {
  return useContext(UserContext)
}
