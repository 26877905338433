import React from 'react'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { ApolloLink } from 'apollo-link'
import { ApolloProvider as ActualApolloProvider } from '@apollo/react-hooks'
import { getLocalToken } from 'react-u5auth'

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
      }
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    setContext((_, { headers }) => {
      const token = getLocalToken()
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : ''
        }
      }
    }),
    new HttpLink({
      uri: process.env.REACT_APP_API_URL,
      credentials: 'same-origin'
    })
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: object => {
      switch (object.__typename) {
        // case 'Something':
        //   return `${object.__typename}:${object.code}`
        default:
          return defaultDataIdFromObject(object)
      }
    }
  })
})

export const ApolloProvider = ({ children }) => (
  <ActualApolloProvider client={apolloClient}>
    {children}
  </ActualApolloProvider>
)
