import React from 'react'
import { AuthContext, useAuthentication } from 'react-u5auth'
import { LoggingInSpinner } from '../presentation/spinners'

const clientId = process.env.REACT_APP_AUTH_CLIENT_ID
const authProvider = process.env.REACT_APP_AUTH_PROVIDER || 'https://login.voteda.org'

export const AuthProvider = ({ children }) => (
  <AuthContext clientId={clientId} provider={authProvider}>
    {children}
  </AuthContext>
)

export const Authenticated = ({ children }) => {
  const { authenticated } = useAuthentication()
  return authenticated ? children : <LoggingInSpinner />
}
