import React from 'react'
import { useLocation } from 'react-router-dom'
import { Alert, Container } from 'react-bootstrap'
import MainNav from './MainNav'

export default function Layout ({ children }) {
  const location = useLocation()

  const alert = location.state && location.state.alert

  return (
    <>
      <MainNav />
      <Container className='my-3'>
        {alert && <Alert variant='info'>{alert}</Alert>}
        {children}
      </Container>
    </>
  )
}
