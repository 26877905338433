import React from 'react'
import { useAuthentication } from 'react-u5auth'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { useUser } from '../infra/user'

export default function MainNav () {
  const { authenticated } = useAuthentication()

  return (
    <>
      <Navbar bg='primary' variant='dark'>
        <Link to='/'>
          <img src='https://static.da-io.net/images/da-logo.png' alt='DA logo' height='38' />
        </Link>
        <LinkContainer to='/'>
          <Navbar.Brand>
            Congress Submissions
          </Navbar.Brand>
        </LinkContainer>
      </Navbar>
      {authenticated && <AuthedNav />}
    </>
  )
}

function AuthedNav () {
  const { user } = useUser()

  return (
    <Nav className='sub-nav d-print-none'>
      <LinkContainer to='/'><Nav.Link>Home</Nav.Link></LinkContainer>
      {user && user.admin && <LinkContainer to='/admin'><Nav.Link>Admin</Nav.Link></LinkContainer>}
      <Nav.Link href='https://login.voteda.org/logout'>Logout</Nav.Link>
    </Nav>
  )
}
