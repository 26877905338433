import React, { Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useUser } from '../infra/user'
import { LoadingSpinner } from '../presentation/spinners'
import { ForbiddenMessage } from '../presentation/alerts'

const IndexContainer = lazy(() => import('./IndexContainer'))
const SubmitContainer = lazy(() => import('./SubmitContainer'))
const EditContainer = lazy(() => import('./EditContainer'))
const DraftCommentContainer = lazy(() => import('./DraftCommentContainer'))
const FinalCommentContainer = lazy(() => import('./FinalCommentContainer'))
const AdminRoutes = lazy(() => import('./AdminRoutes'))

export default function Routes () {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <Route exact path='/'><IndexContainer /></Route>
        <Route path='/document/:id/submit'><SubmitContainer /></Route>
        <Route path='/amendment/:id/edit'><EditContainer /></Route>
        <Route path='/draft/:id/comment'><DraftCommentContainer /></Route>
        <Route path='/final/:id/comment'><FinalCommentContainer /></Route>
        <Route path='/admin'><AdminWrapper /></Route>
      </Switch>
    </Suspense>
  )
}

function AdminWrapper () {
  const { user, loading } = useUser()
  if (loading) return <LoadingSpinner />
  else if (user.admin) return <AdminRoutes />
  else return <ForbiddenMessage forbiddenItem='the admin console' />
}
